<template>
  <div class="home">
    <div class="hero">
      <!-- <img class="hero-image" src="@/assets/images/2.jpg" /> -->
      <section>
        <div class="container">
          <h1>
            We are scaling internet
            connectivity and provide internet for everyone.
          </h1>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped>
.hero {
  width: 100%;
  height: 100vh;
  background: url('../../assets/images/2.jpg');
  mix-blend-mode: normal;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}


.hero:before {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.807);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero .hero-image {
  height: 70%;
  width: 700px;
  height: 700px;

  position: absolute;
  right: -200px;
  top: 15%;
  border-radius: 50%;
  object-fit: cover;
}

.hero .container {
  width: 546px;
  position: absolute;
  bottom: 15%;
}

.hero .container .avatar {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 0.5em;
  object-fit: cover;
  filter: contrast(120%);
  transition: all 0.2s;
}

.hero .container .container-image {
  height: 300px;
  width: 60%;
  object-fit: cover;
  margin-bottom: 1em;
  border-radius: var(--border-radius);
  transition: all 0.5s;
}

.hero .container .container-image:hover {
  object-fit: cover;

  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.hero .container .wrapper {
  padding: 3px;
  border: 1px solid rgba(255, 255, 255, 0.198);
}

.hero h1 {
  line-height: 65px;
  width: 700px;
  color: #ffffff;
  font-size: 50px;
  margin-bottom: 25px;
  transition: all 0.3s;
}

.hero h1:hover {
  font-weight: bold;
  color: var(--primary);
  cursor: pointer;
}

.hero p {
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
}

.hero .player_button {
  display: grid;
  min-width: 152px;
  width: 152px;
  align-items: center;
  grid-template-columns: 30% 70%;
  margin-top: 32px;
}

.hero .player_button p {
  font-size: 16px;
}

@media (max-width: 768px) {
  .hero {
    background-position: top right;
  }

  .hero h1 {
    width: 90%;
    line-height: 30px;
    font-size: 25px;
    font-weight: 600;
  }

  .hero img {
    display: none;
  }

  .hero .container {
    width: 95%;
  }

  .hero .container > p {
  }
}
</style>

<script>
export default {
  name: "Hero_Component",
  components: {},
};
</script>
