<template>
  <footer>
    <div class="center">
      <p>Copyright @ {{ new Date().getFullYear() }}</p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      date: null,
    };
  },
  methods: {},
};
</script>

<style scoped>
/* The footer section */
footer {
  padding: 3em 0px;
  background-color: #ffffff0e;
}

a {
  text-decoration: none;
}

.social {
  width: 20px;
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
}

.footer-nav {
  padding: 20px 0;
  width: 100%;
  line-height: 32px;
}
.footer-nav .heading {
  margin-bottom: 1em;
}

.footer-nav span img {
  width: 20px;
}

.footer-nav ul li {
  font-weight: 300;
  color: #222;
  list-style-type: none;
  font-size: 13px;
  margin-bottom: 8px;
}

@media (max-width: 800px) {
  footer {
    margin-top: 5em;
  }
}

.footer-extension {
  color: white;
  text-align: center;
  margin-top: 2em;
  letter-spacing: 2px;
  padding-bottom: 1em !important;
}

.footer-extension .logo {
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: bold;
  font-size: 12px;
  height: 40px;
  width: 40px;
  margin-bottom: 1.5em;
}

.footer-extension p {
}
/* The footer section End */
</style>
