<template>
  <div class="home">
    <div class="hero">
      <video no-controls muted autoplay loop>
        <source src="@/assets/images/background.mp4" type="video/mp4" />
      </video>
      <section>
        <div class="container">
          <div class="wrapper">
            <div>
              <h1>
                Affordable & Reliable infrastructure for the modern internet.
              </h1>
              <!-- <button class="center button-light mt-2">Contact Sales</button> -->
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped>
.hero {
  width: 100%;
  height: 70vh;
  background: black;
  mix-blend-mode: normal;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

video {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  /* object-position: center center; */
}

.hero:before {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.668);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero .container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero .container .wrapper {
  display: grid;
  height: 100%;
  place-items: center;
  text-align: center;
}

h1 {
  line-height: 60px;
  width: 700px;
  color: #ffffff;
  font-size: 50px;
  margin-bottom: 25px;
  font-weight: 600;
}

h1 span {
  font-family: Poppins;
  color: var(--primary);
}

.hero p {
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
}

.hero .player_button {
  display: grid;
  min-width: 152px;
  width: 152px;
  align-items: center;
  grid-template-columns: 30% 70%;
  margin-top: 32px;
}

.hero .player_button p {
  font-size: 16px;
}

@media (max-width: 768px) {
  .hero {
    height: 90vh;
    padding-top: 3em;
  }

  .hero button {
    margin: 0;
    margin-left: 2.6em;
  }

  h1 {
    width: 90%;
    line-height: 47px;
    font-size: 37px;
    text-align: left;
    margin-left: 1em;
  }

  .hero p {
    width: 90%;
  }

  .hero .container {
    width: 95%;
  }
}
</style>

<script>
export default {
  name: "Hero_Component",
  components: {},
};
</script>
