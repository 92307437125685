<template>
  <Hero />
  <Processes />
  <Hero2 />
  <Contact />
</template>

<script>
import Hero from "./Hero";
import Hero2 from "./Hero2";
import Processes from "./Processes";
// import About from "./About";
import Contact from "./Contact";

export default {
  name: "HomeView",
  components: {
    Hero,
    Hero2,
    Processes,
    Contact,
  },
};
</script>
