<template>
  <section id="contact">
    <div class="contact mt-8 mb-5">
      <div class="contact-head">
        <h2 class="mb-2">Have you tried Rapidnet?</h2>
        <p>
          We're here to help and answer any questions you might have. We'll get
          in touch as soon as possible
        </p>
      </div>

      <div class="contact-grid">
        <img class="child" src="@/assets/images/1.jpg" alt="" />
        <div class="child">
          <form v-on:submit.prevent>
            <h3 class="mb-2">Contact Us</h3>
            <div>
              <label>Email Address</label>
              <input type="email" placeholder="whitneyblessing@gmail.com" />
            </div>
            <div>
              <label>Name</label>
              <input type="text" placeholder="whitneyblessing" />
            </div>
            <div>
              <label>Message</label>
              <textarea rows="10" style="height: 100px"></textarea>
            </div>
          </form>
          <div>
            <a href="mailto:solution@rapidnet.io" target="_blank">
              <button class="wide">Submit Message</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.contact {
  height: 100%;
}
.contact h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
}

.contact p {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
}

.contact-head {
  display: grid;
  place-items: center;
  margin: 0 auto;
  width: 80%;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .contact-head {
    width: 100%;
  }
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.contact-grid .child {
  width: 100%;
  object-fit: cover;
  border-radius: var(--border-radius);
}

.contact-grid img {
  height: 390px !important;
}
</style>

<script>
export default {};
</script>
