<template>
<hr>
  <section class="mt-5 mb-5">
    <div class="processes">
      <div class="">
        <span>UNLIMITED STREAMING FOR: </span>
        <div class="container">
          <h1>Education</h1>
          <h1>Entertainment</h1>
          <h1>Meetins</h1>
          <h1>Interviews</h1>
          <h1>You and more</h1>
        </div>
      </div>
      <div class="">
        <span>RELIABLE INTERNET </span>
        <div class="container">
          <h1>Remote Work</h1>
          <h1>Safe Browsing</h1>
          <h1>Consistent Download</h1>
          <h1>Browsing</h1>
        </div>
      </div>
      <div class="">
        <span>CAN BE USED BY</span>
        <div class="container">
          <h1>Personal</h1>
          <h1>Public</h1>
          <h1>Private Individual</h1>
          <h1>Private Business</h1>
          <h1>Hybrid</h1>
        </div>
      </div>
    </div>
  </section>
  <hr>
</template>

<style scoped>
.processes {
  height: 100%;
  margin-top: 5em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
  align-items: center;
}

span {
  font-size: 12px;
  color: rgb(158, 158, 158);
  text-transform: uppercase;
  letter-spacing: 3px;
}

.processes {
  display: grid;
}

.processes .container {
  margin-top: 1em;
  margin-bottom: 1em;
}

h1 {
  font-weight: bold;
  font-size: 30px;
  transition: all .2s;
}

h1:hover {
  font-weight: bold;
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  /* text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; */
  cursor: pointer;
}
</style>

<script>
export default {
  name: "Processes",
};
</script>
